import { Mentions as $Mentions } from "antd"
import { FieldProps } from "formik"
import * as React from "react"
import { FormikFieldProps } from "../FieldProps"
import Field from "../field"
import { MentionProps as $MentionProps } from "antd/lib/mentions"

export type MentionProps = FormikFieldProps & $MentionProps

export const Mentions = ({
  name,
  validate,
  fast,
  onChange: $onChange,
  onBlur: $onBlur,
  ...restProps
}: MentionProps) => (
  <Field fast={fast} name={name} validate={validate}>
    {({ field: { value, onChange, onBlur }, form }: FieldProps) => (
      <$Mentions
        name={name}
        value={value}
        onBlur={(e) => {
          onBlur(name)
          $onBlur && $onBlur(e)
        }}
        onChange={(event) => {
          form.setFieldValue(name, event)
          $onChange && $onChange(event)
        }}
        {...restProps}
      />
    )}
  </Field>
)

Mentions.Option = $Mentions.Option

export default Mentions
