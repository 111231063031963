import { Form as $Form } from "antd"
import { FormProps } from "antd/lib/form/Form"
import { Field, FieldProps } from "formik"
import * as React from "react"
import { FormItem } from "../form-item"

export var Form = (props: FormProps) => {
  return (
    <Field>
      {({ form: { handleReset, handleSubmit } }: FieldProps) => (
        <$Form onFinish={handleSubmit} onReset={handleReset} {...props} />
      )}
    </Field>
  )
}
;(Form as any).Item = FormItem

export default Form
