import { Input as $Input } from "antd"
import {
  InputProps as $InputProps,
  PasswordProps as $PasswordProps,
  TextAreaProps as $TextAreaProps,
} from "antd/lib/input"
import { TextAreaRef } from "antd/lib/input/TextArea"
import { FieldProps } from "formik"
import * as React from "react"
import Field from "../field"
import { FormikFieldProps } from "../FieldProps"

export type InputProps = FormikFieldProps & $InputProps

interface InputType
  extends React.ForwardRefExoticComponent<
    FormikFieldProps & $InputProps & React.RefAttributes<typeof $Input>
  > {
  Password: React.ForwardRefExoticComponent<
    FormikFieldProps & $PasswordProps & React.RefAttributes<unknown>
  >
  TextArea: React.ForwardRefExoticComponent<
    FormikFieldProps & $TextAreaProps & React.RefAttributes<TextAreaRef>
  >
}

const Input = React.forwardRef(
  (
    { name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...restProps }: InputProps,
    ref: React.Ref<typeof $Input>
  ) => (
    <Field fast={fast} name={name} validate={validate}>
      {({ field: { value, onChange, onBlur } }: FieldProps) => (
        <$Input
          name={name}
          ref={ref as any}
          value={value}
          onBlur={(event) => {
            onBlur(event)
            $onBlur && $onBlur(event)
          }}
          onChange={(event) => {
            onChange(event)
            $onChange && $onChange(event)
          }}
          {...restProps}
        />
      )}
    </Field>
  )
)

const TypedInput = Input as unknown as InputType

TypedInput.Password = React.forwardRef(
  (
    { name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...restProps }: PasswordProps,
    ref: React.Ref<unknown>
  ) => (
    <Field fast={fast} name={name} validate={validate}>
      {({ field: { value, onChange, onBlur } }: FieldProps) => (
        <$Input.Password
          name={name}
          ref={ref as any}
          value={value}
          onBlur={(event) => {
            onBlur(event)
            $onBlur && $onBlur(event)
          }}
          onChange={(event) => {
            onChange(event)
            $onChange && $onChange(event)
          }}
          {...restProps}
        />
      )}
    </Field>
  )
)

TypedInput.TextArea = React.forwardRef(
  (
    { name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...restProps }: TextAreaProps,
    ref: React.Ref<TextAreaRef>
  ) => (
    <Field fast={fast} name={name} validate={validate}>
      {({ field: { value, onChange, onBlur } }: FieldProps) => (
        <$Input.TextArea
          name={name}
          ref={ref}
          value={value}
          onBlur={(event) => {
            onBlur(event)
            $onBlur && $onBlur(event)
          }}
          onChange={(event) => {
            onChange(event)
            $onChange && $onChange(event)
          }}
          {...restProps}
        />
      )}
    </Field>
  )
)

export type PasswordProps = FormikFieldProps & $PasswordProps

export type TextAreaProps = FormikFieldProps & $TextAreaProps

export { TypedInput as Input }
export default TypedInput
